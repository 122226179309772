import React from "react";
import { CartLogin } from "./assets";

const LogoWithText = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "50px",
        left: "50px",
        zIndex: 11111111,
        flexWrap: "nowrap", 
        gap: "5px",
      }}
    >
      <div>
        <img
          src={CartLogin}
          alt="logo"
          style={{
            width: "100%", 
            objectFit: "contain",
          }}
        />
      </div>
      <div>
        <h1
          style={{
            fontWeight: 800,
            fontFamily: "Roboto, sans-serif",
            fontSize: "32px", 
            color: "#fff",
            margin: 0,
            whiteSpace: "nowrap",
          }}
        >
          Growwer
        </h1>
      </div>
    </div>
  );
};

export default LogoWithText;
